.contactContainerBox {
    margin-left: 19.8%;
    width: 100vw;
}

.contactContainer {
    display: flex;
    width: 80%;
}

.form {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
}

/* .form .formLogo {
    width: 450px;
}

.form .formLogo img {
    width: 100%;
    height: auto;
} */

.form img {
    width: 100%;
    height: auto;
}

.formContact {
    background-color: #071f66;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.formContact form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.formContact .firstRow {
    display: flex;
    gap: 1rem;
}

.formContact .firstRow input,
select{
    width: 50%;
    border: 1px solid #fff;
}

.secondRow {
    display: flex;
}

.secondRow .input {
    width: 100%;
}

.formContact input,
textarea,
select {
    background-color: #fff;
    color: black;
    border-radius: .5rem;
    padding: 1rem;
    border: 1px solid #fff;
    font-family: 'Raleway', sans-serif;
    outline: none;
}
/* 
input:focus {
    border: 1px solid yellow;
  } */

.formContact textarea {
    font-family: 'Raleway', sans-serif;
}

.info {
    flex: 1;
    background-color: #d9d9d9;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: end;
    /* justify-content: center; */
}

.infoMail {
    width: 85%;
}

.mailTitle {
    color: black;
    font-size: 1.2rem;
    padding: -10rem;
}

.mailTitle h5 {
    padding: -1rem;
}

.btnsubmit {
    font-family: 'Raleway', sans-serif;
}

.btnsubmit[disabled] {
    /* Styles for disabled button, e.g., opacity, background color */
    opacity: 0.5;
    cursor: default;
  }

.strip {
    width: 80%;
    padding-left: 1rem;
}

.strip img {
    width: 100%;
    height: auto;
}


@media screen and (max-width: 999px) {
    .contactContainerBox {
      margin-left:24.9%;
    }

    .contactContainer {
        width: 75%;
    }
    
    .contactContainer {
        display: flex;
        flex-direction: column;
    }

    .info {
        padding: 1.5rem 0;
    }

  }
  
  @media screen and (max-width: 768px) {
    .contactContainerBox {
      margin-left: 0;
      width: 100%;
    }

    .contactContainer {
        width: 100%;
    }

    .form .formImg {
    }
  }

  @media screen and (max-width: 500px) {

    .form .formImg {
    }

    .formContact {
    }
    
  }

  
  @media screen and (max-width: 450px) {


    
  }

  