.workContainer {
    margin-left: 20%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.workContent {
    /* margin-top: 1.5rem; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.workImage {
}

.workImage img {
    height: auto;
    width: 100%;
}

.workText {
    display: flex;
    justify-content: end;
    align-items: end;
    text-align: end;
}

.workText p {
    font-weight: bold;
    width: 80%;
    line-height: 1.5;
}

.workApply {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 5rem;
    margin-top: 2rem;
    width: 80%
}

.workBox {
    width: 45%;
    display: flex;
    align-items: start;
    flex-direction: column;
}

.workBox h3 {
    color: #80a6a3;
    font-size: 1.2 rem;
    font-weight: bold;
}

.workBox p {
    line-height: 1.6;
    margin-top: 0;
}

.workApply .applyBtn {
    display: flex;
    gap: 1.3rem;
    align-items: center;
}

.workApply .applyBtn .btn {
    padding: 1rem;
    border: 1px solid black;
    background-color: white;
    color: black;
    font-weight: bold;
}

.workApply .applyBtn .btn:hover {
    background-color: #64a953;
    color: #fff;
    border: 1px solid #fff;
}

.workApply .applyBtn a {
    text-decoration: underline;
    color: #80a6a3;
    cursor: pointer;
}

.workApply .applyBtn .role {
    text-decoration: underline;
    color: #80a6a3;
    cursor: pointer;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Grey overlay */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    width: 90%;
    height: 90%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .popup::-webkit-scrollbar {
  width: 3px;
  }
  
  .popup::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .popup::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 4px;
  }
  
  
  .popup .btnPop {
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.7rem;
  }

  .iframe-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    padding-top: 56.25%; /* 16:9 aspect ratio (adjust as needed) */
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1330px) {
    .workApply {
        width: 100%
    }
    .workBox {
        width: 42%;
    }
  }

  
  @media screen and (max-width: 999px) {
    .workContainer {
      margin-left:25%;
    }

    .workBox {
        width: 90%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .workContainer {
      margin-left: 0;
      width: 95%;
    }
    .popup {
        width: 50%;
        height: 50%;
      }

      .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Grey overlay */
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .popup {
        width: 90%;
        height: 90%;
      }
      
      .popup .btnPop {
        background-color: transparent;
        color: #111;
        font-weight: bold;
        border: none;
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 1.2rem;
      }
  }

  @media screen and (max-width: 768px) {
    .popup {
      width: 70%;
      height: 70%;
    }

  }
  