.acumaticaContainer {
  margin-left: 20%;
  /* padding: 1.5rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.general {
/* padding: 1.5rem; */
}

.pallCont {
width: 60%;
z-index: 1;
}

.sectionTitle {
  text-align: center;
  font-size: 2rem;
  color: gray;
}

.Erp {
  text-align: start;
}

.acumaticaContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.acumaticaEdit {
  flex-wrap: wrap;
}

.imageEdit {
  flex: 40%
}

.textEdit {
  flex: 40%;
}

.imageContainer { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: .5rem;
}

.imgPall {
  margin-top: 2.2rem;
  border-bottom: 2px solid gray;
}
/* 
.imgpally {
  border: 1px solid red;
  width: 150px;
} */

.imgpally img {
  height: auto;
  width: 100%;
}

.imageContainer img { 
  width: 100%; 
  height: auto;
}

.textContainer h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.textContainer p {
  font-size: 16px;
  line-height: 1.5;
}

.textContainer a {
  color: #111;
  font-size: 18px;
  text-decoration: underline;
}

.textContainer a:hover {
  color: #64a953;
}

.AcumaticaModules {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container {
  width: 50%;
}

.AcumaticaModules ul {
  list-style-type: none;
}

.AcumaticaModules ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #111;
  padding: 1.1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.containerLink {
  text-decoration: none;
  color: #111;
  font-size: 1.5rem;
}

.containerLink:hover {
  color: #64a953;
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .acumaticaContainer {
    margin-left: 0;
    width: 100%
  }
  .acumaticaContent {
    padding: 0 20px;
  }
}

/* account software */

.AccountContainer {
  width: 100%;
  margin-top: 2.5rem;
  z-index: 1;
}

.accountsSoftware {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.software {
  position: relative;
  width: 23%;
  height: 400px;
  /* background-color: cover; */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  text-align: center;
}

.software a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 2rem;
  z-index: 1111111;
}

.software a:hover {
  color: #64a953;
}

.software::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Acumatica */
.acumaticaContent1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: center;
}

.Acum {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid red; */
}


.acumLogo {
  border-bottom: 2px solid gray;
}

.line {
  width: 100%;
  margin-top: -3rem;
  height: 2px;
  background-color: gray;
}

.acumLogo img {
  height: auto;
  width: 100%;
}

.acumaticaContent1 p {
  line-height: 1.5;
  font-size: 1.1rem;
  font-weight: 550;
}

.vivid {
  width: 100%;
  /* margin-left: -1.5rem;
  margin-right: -1.5rem; */
  background-color: #d9d9d9;
}

.videoContainer {
  /* margin: 2.5rem 0;
  border: 1px solid red; */
  display: flex;
  justify-content: center;
  /* margin-top: 1rem; */
}

.acumatica2 {
  /* flex-wrap: wrap; */
  /* gap: 1rem;
  width: 90%; */
}

.acumatica3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2.5rem;
}

.backdrop {
  background-color: #d9d9d9;
  margin-top: 2.5rem;
}

.image2 {
  flex: 40%;
}

.textContainer2 {
  flex: 40%;
  align-items: center;
  padding: 1rem;

}

.genTitle {
  text-align: start;
  font-size: 2rem;
  color: gray;
  margin-bottom: -.4rem;
}

.textContainer2 p {
  line-height: 1.5;
}

.accountsSoftware {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

/* palladium */
.acumaticaContainer .two {
  display: flex;
  flex-direction: column;
  /* gap: .5rem; */
  /* margin-top: -1.5rem; */
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; */
  width: 50%;
  text-align: center;
}

.acumaticaContainer .two p {
  /* margin-top: -1rem; */
}

.acumaticaContainer .buttons {
  display: flex;
  gap: 3rem;
}

.buttons .btnPal {
  padding: .5rem 1rem;
  background-color: #fff;
  border: 1px solid black;
  color: black;
  border-radius: .5rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.btnPal:hover {
  color: #fff;
  background-color: #64a953;
  border: 1px solid #64a953;
}

.btnAcum {
  padding: .5rem 1rem;
  background-color: #fff;
  border: 1px solid gray;
  color: gray;
  border-radius: .5rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.btnAcum:hover {
  color: #fff;
  background-color: #64a953;
}

.palladiumVersions {
  margin-top: 4rem;
  z-index: 1;
}

.pallSoftware {
  width: 25%;
  height: auto;
  padding: .8rem;
  line-height: 1.6;
  background-size: cover;
  z-index: 1;
}

.pallSoftware::after {
  background-color: rgba(0, 0, 0, 0);
}

.pallSoftware button {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
}


.pallSoftware button:hover {
  background-color: #fff;
  border: 1px solid black;
  color: black;
}

.pallContent {
  margin-top: 2rem;
  width: 77%;
}

.pallContent1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pallImage {
  flex: 25%;
}

.pallImage img {
  width: 75%; 
  height: auto;
}

.pallDesc {
  flex: 70%;
  text-align: center;
}

/* general image */

.GeneralContainer {
margin-top: 3rem;
display: flex;
align-items: center;
flex-direction: column;
}

.GeneralImage {
display: flex;
justify-content: center;
align-items: flex-end;
flex-wrap: wrap;
margin-left: 1%;
}

.imageBox {
display: flex;
align-items: flex-start;
justify-content: flex-start;
width: 17%;

@media screen and (max-width: 999px) {
  width: 33%; 
}

@media screen and (max-width: 768px) {
  width: 40%; 
}

@media screen and (max-width: 480px) {
  width: 65%; 
}
}

.imageBox img {
height: auto;
width: 100%; 
}

.bronchureBox {
display: flex;
flex-wrap: wrap;
/* border: 1px solid red; */
/* overflow-x: auto;
border: 1px solid red; */
}

.iframe {
/* border: 1px solid red; */
/* margin-left: -8rem; */
}

.Slider {
text-align: start;
padding: 1.5rem;
width: 80%;
border-radius: 1px solid red;
}

.sectionTitle {
text-align: center;
font-size: 2.5rem;
color: gray;
}

.portfolioSlider {  
margin: 0 auto;
padding-left: 3rem;

}

.carousel .control-dots {
margin-top: -10rem !important
}

.carousel .control-dots .dot {
background-color: #007bff !important;
}

.carousel .control-dots .dot.selected {
}

.carousel.carousel-slider .control-arrow {
background-color: #d9d9d9 !important;
/* top: 0; */
/* color: #fff; */
/* font-size: 26px; */
/* bottom: 0; */
/* margin-top: 0; */
/* padding: 5px; */
}
.carousel .control-prev.control-arrow {
/* left: 0; */
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
/* transition: all .25s ease-in; */
/* opacity: .4; */
filter: alpha(opacity=40);
/* position: absolute; */
/* z-index: 2; */
/* top: 20px; */
/* background: none; */
/* border: 0; */
/* font-size: 32px; */
/* cursor: pointer; */
}

/* Custom styles for the dots */
.custom-carousel-dot {
width: 10px; /* Adjust the width of the dots */
height: 10px; /* Adjust the height of the dots */
background-color: red; /* Change the background color of the dots */
border-radius: 50%; /* Ensure the dots are circular */
margin: 0 5px; /* Adjust the spacing between the dots */
}

/* Custom styles for the active dot */
.custom-carousel-dot.active {
/* background-color: #ff6347; */
}


/* Custom styles for the Carousel arrows */
.carousel-arrow {
/* Add your custom styles for the arrows */
/* background-color: red; */
/* color: green; */
}

/* Custom CSS to make arrows always visible */
.carousel .control-arrow {
opacity: 1 !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
/* transition: all .25s ease-in;
opacity: .4;
filter: alpha(opacity=40);
position: absolute;
z-index: 2;
top: 20px;
background: none;
border: 0;
font-size: 32px;
cursor: pointer; */
color: 'red' !important;
}

/* Custom styles for the previous arrow */
.carousel-arrow.prev {
/* Add your custom styles for the previous arrow */
}

/* Custom styles for the next arrow */
.carousel-arrow.next {
/* Add your custom styles for the next arrow */
}

.page {
  width: 100%;
  height: 80%;
}

.page img {
  height: auto;
  width: 100%;
}

/* pall datasheet */
.aside {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 20%;
  overflow: scroll;
  /* padding-top: 10rem;; */
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  /* border: 1px solid red; */
}

.aside iframe {
  /* border: 1px solid red; */
}

.aside::-webkit-scrollbar {
  width: 3px;
}

.aside::-webkit-scrollbar-track {
    background: transparent;
}

.aside::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 4px;
}

.fab {
  display: none
}

.pallMobile {
  padding: 1rem;
  background-color: #d9d9d9;
}

@media screen and (max-width: 999px) {
  .fab {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: white;
    padding: 10px 20px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
  }
  .aside {
    display: none;
  }

  .pallCont {
    width: 75%;
  }

  .acumaticaContainer {
   margin-left: 25%;
  }

  /* Acumatica */
  .acumaticaContent1 {
    width: 68%;
  }

  .acumaticaContent1 p {
    line-height: 1.3;
    font-size: 1rem;
    font-weight: 500;
  }

  .videoContainer iframe {
    width: 500px;
    height: 400px;
  }

  /* .acumaticaContent {
    display: flex;
    border: 1px solid red;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  } */
  .acumatica3 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2.5rem;
  }

  .acumatica3 .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .acumatica3 .textContainer2 {
    margin-top: -3rem;
  }

  .acumatica3 .textContainer2 h3 {
    margin: 0;
    display: none;
  }

  .acumatica3 .textContainer2 p {
    text-align: center;
  }

  .accountsSoftware {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .accountsSoftware .pallSoftware {
    width: 50%;
    height: auto;
  }

  .software {
    width: 35%;
    height: 300px;
  }

  .backdrop {
    display: flex;
    flex-direction: column;
  }
  .backdrop .imageContainer {
  }
  .backdrop .imageContainer iframe {
    /* width: 350px;
    height: 150px; */
  }
  .backdrop .textContainer2 {
  }
  .textContainer2 {
    width: 80%;
    text-align: center;
  }
}

.img-body img {
  height: auto;
  width: 100%;
}

/* Custom styles for Carousel arrows */
.carousel .control-arrow {
color: red; /* Change arrow color */
font-size: 24px; /* Change arrow size */
}

/* Custom styles for Carousel dots */
.carousel .carousel-slider .control-dots {
bottom: 10px; /* Adjust dot position */
}

.carousel .control-dots .dot {

}



/* Custom styles for active dot */
.carousel .control-dots .dot.selected {
background-color: green; /* Change active dot color */
}

.carousel.carousel-slider .control-arrow{
top:0;
color:#111;
font-size:26px;
bottom:0;
margin-top:0;
padding:5px
}
.carousel.carousel-slider .control-arrow:hover{
/* background:rgba(0,0,0,0.2) */
background-color: #fff;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff !important;
}

@media screen and (max-width: 768px) {
  /* Acumatica */
  .acumaticaContent1 {
    width: 68%;
  }

  .backdrop .imageContainer {
    padding-top: 1.2rem;
  }

  .pallCont {
    width: 100%;
  }

  .backdrop .imageContainer iframe {
    width: 350px;
    height: 250px;
  }

  .accountsSoftware .pallSoftware {
    width: 80%;
    height: auto;
  }

  .genTitle {
    text-align: center;
  }

  .acumaticaContainer {
    margin-left: 0;
   }
  
  .videoContainer iframe {
    width: 400px;
    height: 300px;
  }

  .accountsSoftware {
    display: flex;
    flex-direction: column;
  }

  .software {
    width: 80%;
    height: 400px;
  }

  .soft {
    width: 80%;
    height: 200px;
  }

  .backdrop {
    margin-top: 0;
  }

  .two .buttons .btnPal {
    margin-top: 0;
  }
    
  .palladiumVersions {
    margin-top: 2rem;
  }

  .pallContent {
    width: 83%;
  }
  
  .pallContent1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .pallImage {
    flex: 50%;
  }

  .pallImage img {
    width: 100%; 
    height: auto;
  }
  .pallDesc {
    flex: 50%;
    text-align: center;
  }

}

@media screen and (max-width: 468px) {
  /* Acumatica */
  .acumaticaContent1 {
    width: 68%;
  }

  .videoContainer iframe {
    width: 300px;
    height: 200px;
  }

  .backdrop .imageContainer iframe {
    width: 300px;
    height: 225px;
  }

  .soft {
    height: 130px;
  }

}