   /* Menu.css */

.menuContainer {
    height: 100vh;
    width: 20%;
    position: fixed;
    top: 0;
    left: 0;
    border-right: 1px solid #d9d9d9;
    z-index: 111;
  }
  
  .menuBox {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
  }
  
  .menuLogo {
    width: 80%;
    max-width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 4.5rem;
  }
  
  .menuList {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .ListLink {
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .menuBox a {
    text-decoration: none;
    color: gray;
  }
  
  .menuBox a:hover {
    color: #64a953;
    font-weight: bold;
  }

  .sublink {
    list-style: none;
  }

  .sublinkList {
    margin-left: -.7rem;
    padding-top: 1rem;
  }

  .activeLink {
    color: green;
  }

  .visible {
    display: none;
  }
  
  
  /* LANDING CSS */

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.005); /* Adjust the alpha value for transparency */
    z-index: 1; /* Set a higher z-index to ensure it appears above other elements */
  }
  
  .landingContainer {
    min-height: 100vh;
    background-image: url('../assets/fkl2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Ensure overlay is positioned relative to this container */
  }

  .landingBox {
    z-index: 999;
  }
  
   
  /* .landingContainer {
    min-height: 100vh;
    background-image: url('../assets/fkl2.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  } */

  .navbar {
    display: none;
    align-items: center;
    /* justify-content: center; */
    gap: 1.3rem;
    padding: 0 1.5rem;
    height: 60px;
    padding-top: .5rem;
  }

  .navbar .bars{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.1rem;
    cursor: pointer;
  }

  .navbar .navbarLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
  }

  .navbar .navbarLogo img {
    height: auto;
    width: 100%;
  }

  .landingBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landingBox h1 {
    font-size: 3.8rem;
    text-align: center;
    line-height: 1.15;
  }

  .landingBox p {
    font-size: 1.3rem;
    text-align: center;
    margin-top: -1rem;
  }
  
  .landingBox .button {
    border: 1px solid gray;
    background-color: transparent;
    border-radius: .3rem;
    color: white;
    padding: 1rem;
    font-size: .9rem;
    text-decoration: none;
  }

  .landingBox .button:hover {
    background-color: #64a953;
    cursor: pointer;
    border: #64a953;
  }


    /* IndustryERP CSS */

  .container{
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
  .content{
    font-weight:bold;
    align-items: center;
    font-size: 15px;
    width: auto;
    height: 100vh;
    margin-top: 20px;
  }

  .solutions{
    display: flex;
    font-style:normal;
    box-sizing: border-box;
    position: absolute;
  }

  .solution{
    flex: 1;
    padding: 20px;
    max-height: 100%;
  }

  .pictures{
    display: flex;
    box-sizing: border-box;
    position:relative;
  }
  .picture{
    flex: 1;
    padding: 20px;
    padding-bottom: 0px;
    max-height: 100%;
  }

  .drop {
    margin-top: 30px;
    height: auto;
    display: block;
  }

  .heading{
    text-decoration:wavy;
    font-size: 24px; 
    font-weight: bold; 
    color: #333; 
    background-color: #f0f0f0; 
    padding: 10px; 
    border-radius: 5px; 
    text-align: center; 
  }

    /* industry erps styles */

  .industryContainer {
    margin: 0 auto;
    padding: 0 4rem;
  }

  .sectionTitle {
    text-align: center;
    margin-bottom: 30px;
  }

  .industyBox {
    display: flex;
    justify-content: space-between;
  }

  .erpBox {
    width: calc(33.33% - 20px);
    padding: 1.3rem;
  }

  .erpBox .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 150px;
  }

  .erpBox .image img {
    width: 100%; 
    height: auto;
  }
  .erpBox h4 {
    margin-top: 10px;
  }

  .erpBox p {
    font-size: 14px;
    line-height: 1.7;
  }

  .erpBox button {
    border: 1px solid gray;
    background-color: transparent;
    border-radius: .3rem;
    color: black;
    padding: .7rem 2rem;
    font-size: .9rem;
  }

  .erpBox button:hover {
    background-color: #64a953;
    cursor: pointer;
    border: #64a953;
    color: #fff;
  }

  /* portfolio styles */

  .portfolioContainer {
    text-align: start;
    /* padding: 1.5rem; */
  }

  .sectionTitle {
    text-align: center;
    font-size: 2.5rem;
    color: gray;
  }

  .portfolioSlider {  
    margin: 0 auto;
    border: 1px solid red;
    /* padding-left: 3rem; */

  }

  .img-bod {
    /* width: 350px; */
    /* height: 40vh; */
    /* aspect-ratio: 1/1;
    display: inline-block; */

    /* width: 100%; 
    height: auto;
    max-width: 100%; 
    max-height: 100%; 
    height: 80vh; */
    width: 100%;
    height: 600px;
    /* height: auto; */
    /* width: 80vw;
    border: 1px solid red; */
  }
  
  .img-bod img {
    display: flex;
    margin: 0 auto;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .sliderTitle {
    color: #111;
    font-size: 1.3rem;
    font-weight: 550;
  }
  
  .desc {
    text-align: start;
    list-style: none;
    padding-left: 0;
  }

  .desc li:before {
    content: '✓';
    color: #64a953;
  }

  .next-slick-arrow {
    position: absolute;
    /* top: -60px;
    left: -18px; */
    cursor: pointer;
  }

  .right-arrow {
    margin-left: -3.5rem;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  /* contact styles */

  .contactBox {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .open-hours {
    text-align: center;
    margin-bottom: 2rem;
  }

  .open-hours h2 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .open-hours ul {
    list-style-type: none;
    padding: 0;
  }
  
  .open-hours li {
    margin-bottom: 5px;
  }

  .selected {
    color: #64a953;
    font-size: 1rem;
  }
  
  
  .toggle-arrow {
    cursor: pointer;
    color: blue; 
  }

  .portalLink span a {
    color: #64a953;
    font-size: 1rem;
    font-size: 500;
    text-decoration: none;
  }

 /* footer styles */

 .FooterBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
 }

 .FooterTitle {
  text-align: center;
  font-size: 1.7rem;
  color: gray;
 }

 .SocialLink {
  /* border: 1px solid red; */
 }

 .SocialLink ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 0;
 }

 .SocialLink ul li {
  list-style: none;
  color: #64a953;
  cursor: pointer;
  font-size: 1.5rem;
 }

 .SocialLink ul li a {
  color: #64a953;
 }

 .copyright {
  color: gray
 }

  
 .brochure-container {
  border: 1px solid red;
  position: relative;
  width: 190px;
  height: 300px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: white;
  font-size: 30px;
  margin: 0 10px;
  cursor: pointer;
}

.flipbook-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-overlay {
  z-index: 99999999999;
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999999;
}

.menupop {
  list-style: none;
  text-align: center;
  padding: 0;
}

.menupop .ListLink .sublinkList{
  list-style-type: none;
}

.menupop .ListLink .link{
  text-decoration: none;
  font-size: 1.1rem;
  color: grey;
}


.xbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.dialogPop {
  dispaly: none;
}

.MuiPaper-root-MuiDialog-paper {
  /* justify-content: center;
  align-items: center;
  height: calc(100% - 64px);*/
  width: 700px; 
}

@media screen and (max-width: 999px) {
  .menuContainer {
    width: 25%;
  }

  .landingBox {
    padding: 1rem;
  }

  .landingBox h1 {
    font-size: 2.7rem;
    text-align: center;
    line-height: 1.15;
  }

  .landingBox p {
    font-size: 1.2rem;
    text-align: center;
    margin-top: -1rem;
  }

  .industryContainer {
    padding: 0 2rem;
  }

  .industryContainer .sectionTitle {
    margin-bottom: 0;
    font-size: 2rem;
    margin-top: 2rem;
  }

  .industryContainer .sectionTitle h5 {
    margin: -.00005rem 0;
  }

  .industyBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
   .erpBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
   }

   .erpBox h4 {
    display: none;
   }

   .erpBox .image {
    margin-top: -1rem;
   }

   .erpBox p {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.2;
    margin-top: -1rem;
   }

   .SocialLink ul {
    gap: 1.9rem;
   }

   .FooterBox {
    margin-top: 1rem;
   }

   .FooterTitle h5 {
    margin: 0;
    font-size: 1rem;
   }

   .copyright {
    text-align: center;
   }

   .portfolioContainer {
    /* text-align: start; */
    padding: 0;
  }

  .sectionTitle {
    text-align: center;
    font-size: 2.5rem;
    color: gray;
  }

  .portfolioSlider {  
  }

    .img-bod {
      /* width: 350px; */
      /* height: 40vh; */
      /* aspect-ratio: 1/1;
      display: inline-block; */
      /* height: auto;
      max-width: 100%; 
      max-height: 100%;  */
      /* height: 50vh; */
      /* width: 80vw;
      border: 1px solid red; */
      height: auto;
    }
    
    .img-bod img {
      /* display: flex;
      margin: 0 auto;
      object-fit: contain; */
      width: 100%;
      height: auto;
    }

    .next-slick-arrow {
      position: absolute;
      /* top: -60px;
      left: -18px; */
      cursor: pointer;
      display: none;
    }
  
    .right-arrow {
      margin-left: 0;
    }
}

@media (max-width: 768px) {
  .dialogPop {
    dispaly: inline-block;
  }
  .menuContainer {
    display: none;
  }
  .navbar {
    display: flex;
    border-bottom: 1px solid #d9d9d9;
  }
  .industryBox {
    flex-direction: column;
  }
  .map-iframe {
    height: 250px; /* Set the desired height for mobile devices */
  }
}

/* 
@media screen and (max-width: 768px) {

} */


