.homeContainer {
    margin-left: 20%;
    width: 80%;
}

@media screen and (max-width: 999px) {
    .homeContainer {
      margin-left: 25%;
        width: 75%;
    }
  }
  

@media screen and (max-width: 768px) {
    .homeContainer {
      margin-left: 0;
        width: 100%
    }
  }
  
  @media screen and (min-width: 1024px) {
    /* CSS rules for screens with a minimum width of 1024px */
  }
  