.payrollContainer {
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.sectionTitle {
  text-align: center;
  font-size: 2rem;
  color: gray;
}

.containerTop {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 0;
}

.containerFeatures {
    margin-top: -1.5rem;
    text-align: center;
}

.features {   
    display: flex;
    gap: 2rem;
    margin-top: -.7rem;
}

.featurecontainer h4 {
    font-weight: 600;
    background-color: #64a953;
    border-radius: .5rem;
    color: white;
    padding: .8rem;
    text-align: center;
}

.featBox {
  margin-bottom: 1.2rem;
}

.btnPal {
  border: 1px solid grey;
  padding: .5rem 1rem;
  background-color: #fff;
  color: grey;
  margin-top: 3rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
}

.palbtn {
  border: 1px solid grey;
  padding: .5rem 1rem;
  background-color: #fff;
  color: grey;
  margin-top: 3rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  border-radius: .5rem;
}

.palbtn:hover {
  color: #fff;
  background-color: #64a953;
  border: 1px solid #64a953;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70;
}

.iconBox {
    width: 23%
}

.iconComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .7rem;
    padding: 1rem;
}

.table {
    width: 55%;
    margin-top: 1.2rem;
}

.tableContainer {
    text-align: center;
  }
  
  .title {
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  
  .price-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .price-table th,
  .price-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .price-table th {
    background-color: #f2f2f2;
  }
  
  .dot1,
  .dot2,
  .dot3 {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .dot1 {
    background-color: #94d023;
  }

  .dot2 {
    background-color: #69c6ff;
  }
  
  .dot3 {
    background-color: #465171;
  }
  
  .price-table .lite {
    background-color: #94d023;
    color: #fff;
  }

  .price-table .premier {
    background-color: #69c6ff;
    color: #fff;
  }

  .price-table .master {
    background-color: #465171;
    color: #fff;
  }

  @media screen and (max-width: 999px) {
    .payrollContainer {
      margin-left:25%;
    }

    .containerFeatures {
      width: 90%;
    }

    .features {
      padding: 0 1rem;
    }

    .iconBox {
      width: 33%;
    }


    .featBox .row {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .payrollContainer {
      margin-left: 0;
      width: 100%;
    }

    .containerFeatures {
      width: 90%;
    }

    .features {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .featurecontainer h4 {
      margin: .5rem;
    }

    .iconBox {
      width: 80%;
    }

    .iconComponent {
      display: flex;
      justify-content: flex-start;
      padding: .5rem;
  }

    .featBox {
      width: 100%;
    }

    .featBox .row {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .featBox .row .none {
      display: none;
    }

    .table {
      width: 90%;
      margin-top: .9rem;
    }
    
  }