.referralContainer {
    margin-left: 20%;
}

.flexContainer {
    display: flex;
}

.refer {
    flex: 1;
    padding: 1.3rem;
}

.form {
    display: flex;
    gap: 1rem;
} 

.refer img {
    height: auto;
    width: 100%;
}

.terms {
    flex: 1;
    padding: 1.3rem;
    background-color: #d9d9d9;
}

.terms ul li {
    margin-bottom: 8px;
}

.terms ol li {
    margin-bottom: 10px;
}

.referForm {
    background-color: #194345;
    border-radius: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: -2rem;
}

.referForm form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.referForm .firstRow {
    display: flex;
    gap: 1rem;
}

.referForm .firstRow select,
input{
    width: 50%;
    border: 1px solid #fff;
}

.referForm input,
textarea,
select {
    background-color: #fff;
    color: black;
    border-radius: .5rem;
    padding: 1rem;
    border: 1px solid #fff;
    font-family: 'Raleway', sans-serif;
    outline: none;
}

.circleList {
    list-style-type: circle;
    font-weight: bold;
}

@media screen and (max-width: 999px) {
    .referralContainer {
      margin-left:25%;
    }
    
    .flexContainer {
        display: flex;
        flex-direction: column;
    }

    .info {
        padding: 1.5rem 0;
    }

  }
  
  @media screen and (max-width: 768px) {
    .referralContainer {
      margin-left: 0;
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {


    
  }